<template>
  <div class="mm-client-layout">
    <WarningMessage />
    <Header />

    <div class="mm-client-layout__wrapper">
      <slot />
    </div>
    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import Header from 'components/layouts/client/Header.vue';
import { useClientGuard } from 'composables/useClientGuard';
import { UserHelper } from 'utils/userHelper.util';
import { useMainTitle } from 'composables/useMainTitle';
import { useServerSsoAuth } from 'composables/useAuthHelper';
import { useRefreshBasketData } from 'composables/useRefreshBasketData';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';

setAuthCookieHeaders();
const isDomainAuth = await useDomainAuth();

await useServerSsoAuth();

useMainTitle();

await useSetServerUserInfo();

useCheckInternalUser();

if (!isDomainAuth && !UserHelper.isClient) {
  navigateTo('/');
}

useSetClientUserInfo();
useRefreshBasketData();


onBeforeMount(async () => useClientGuard());
</script>

<style lang="scss" scoped>
.mm-client-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;

  &.overflow {
    overflow: hidden;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  :deep(.mm-client-header) {
    .mm-client-header__menu {
      margin-left: 32px;

      .mm-client-header__menu-item {
        padding: 0;
        margin-right: 40px;

        &:not(:first-child) {
          margin-left: 0;
        }

        a {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-client-layout {
    &__wrapper {
      padding-bottom: 56px
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-client-layout {
    &__wrapper {
      padding-bottom: 56px;

      &:has(.mm-client-orders__filters-list) {
        padding-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 680px) and (max-width: 768px) {
  .mm-client-layout {
    :deep(.mm-header) {
      margin: 0;
      border-radius: 6px 6px 0 0;
    }

    :deep(.mm-footer) {
      margin: 4px;
      width: calc(100% - 8px);
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 679px) {
  .mm-client-layout {
    :deep(.mm-header) {
      margin: 0;
      border-radius: 6px 6px 0 0;
    }

    :deep(.mm-footer) {
      margin: 4px;
      width: calc(100% - 8px);
    }
  }
}
</style>
